/* List view and each entry is displayed by templates/location.js */
import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'

const regionalMinistries = ({ location }) => {
  const configID = '5fA5KrRRsXDxe7Nd3QKBeu'
  const queryLocations = useStaticQuery(graphql`
    {
      allContentfulLocations(sort: { fields: sortOrder, order: ASC }) {
        edges {
          node {
            id
            name
            slug
            address
            phone
            mobile
            email
            logo {
              file {
                fileName
                url
              }
            }
          }
        }
      }
    }
  `)

  const TYPE = 'Missions Interlink'
  const IMAGE_HEIGHT = 150
  const data = queryLocations.allContentfulLocations.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.name,
    image: obj.node.logo,
    imageHeight: IMAGE_HEIGHT,
    slug: `/about${obj.node.slug}`,
    category: null,
    type: TYPE
  }))

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List data={data} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

export default regionalMinistries

regionalMinistries.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
